import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Link } from "@reach/router";
import { Layout, PostCard, Pagination } from "../components/common";
import { MetaData } from "../components/common/meta";
import { Row, Col } from "reactstrap";

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="my-4">
                    <Row className="justify-content-center">
                        {renderAboutInfo()}
                        {renderLatestPostFeed(posts, pageContext)}
                    </Row>
                </div>
            </Layout>
        </>
    );
};

function renderAboutInfo() {
    return (
        <Col className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11">
            <h1 className="special-heading mb-5 text-center">
                What is GajGal?
            </h1>
            <p>
                The world of work has fundamentally changed in the past few
                years and this change is continuing to expand into a new
                approach to employment.
            </p>
            <ul>
                <li>
                    Employers are increasingly reluctant to hire full time
                    employees with their resultant long term costs, training and
                    skills development commitments.
                </li>
                <li>
                    Employees have seen, through the recent recession, that
                    loyalty and access to big company benefits are not
                    guaranteed, even for experienced top performers.
                </li>
            </ul>
            <p className="text-left">
                <strong>In the “New World of Work” </strong>
            </p>
            <ul>
                <li>
                    Companies want access to highly skilled staff with more
                    flexibility to capitalize on individuals experience and
                    skills for specific workloads without the increased burden
                    of full time staff commitments.
                </li>
                <li>
                    Individuals would love the flexibility and lifestyle
                    benefits to work more on their own terms, but at the same
                    time need access to Benefits such as Healthcare, Insurance,
                    retirement savings and financial products that traditionally
                    come with full time employment.
                </li>
            </ul>
            <p className="text-left ">
                Many mothers with solid work experience have left their full
                time careers to start a family and would like to return to work,
                but on their own terms.
            </p>
            <div className="float-left d-inline-block">
                <span className="float-left pr-3">
                    <img src="images/about.jpg" className="align-top" />
                </span>
                <p className=" p-3">
                    While part time jobs and work from home opportunities have
                    always been an option, there are increasing needs for new
                    more flexible work arrangements, to access great talent that
                    in the past may have been excluded from the traditional 9-5
                    work environment.
                </p>
            </div>
            <h1 className="special-heading mb-5 text-center">What we offer</h1>
            <p className=" p-3">
                GajGal is an expanding community of mothers helping mothers. Our
                motto “Get a Job | Get a Life” is based on the belief that for
                mothers wanting to get back into the workforce, finding a job
                with “the right level” of flexibility, empowers working mothers
                to live life on their own terms and better control their own
                destiny. GajGal aims to address this “new reality” through four
                core components:
            </p>
            <ul>
                <li>
                    <strong>Job Search and Matching</strong> tools to streamline
                    finding the perfect fit for flexible work arrangements.
                </li>
                <li>
                    <strong>Access to Benefits</strong> that in the traditional
                    work environment have been provided by full time employers.
                    GajGal helps to provide a critical mass of members to ensure
                    access to a range of benefits options that we are
                    continually working to expand and improve on.
                </li>
                <li>
                    <strong>Working Mother Community</strong> to provide access
                    to news, blogs and articles of interest and importance to
                    working mothers and a social networking community that
                    working mothers can interact with and draw on for support.
                </li>
                {/* <li>
                    <strong>The Entrepreneur Zone</strong> is for mothers aiming
                    to start their own business and expand on the concepts of
                    working mothers helping working mothers succeed.{" "}
                </li> */}
            </ul>
        </Col>
    );
}

function renderLatestPostFeed(posts) {
    return (
        <React.Fragment>
            <Col className="col-xl-2 col-3 d-xs-none">
                <h1 className="special-heading mb-5 text-center">
                    <Link to="/blog-posts/latest">Latest posts</Link>
                </h1>
                <section id="latest-articles">
                    {posts.map(({ node }) => (
                        // The tag below includes the markup for each post - components/common/PostCard.js
                        <PostCard key={node.id} post={node} />
                    ))}
                </section>
            </Col>
            <Col className="col-12 d-xl-none d-lg-none d-md-none d-sm-none">
                <h1 className="special-heading mb-5 text-center">
                    <Link to="/blog-posts/latest">Latest posts</Link>
                </h1>
                <section id="latest-articles">
                    {posts.map(({ node }) => (
                        // The tag below includes the markup for each post - components/common/PostCard.js
                        <PostCard key={node.id} post={node} />
                    ))}
                </section>
            </Col>
        </React.Fragment>
    );
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query GhostPostQuery($limit: Int!) {
        allGhostPost(
            sort: { order: DESC, fields: [updated_at] }
            limit: $limit
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
